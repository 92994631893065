import "babel-polyfill";

import "jquery";
import "bootstrap";
import "popper.js";
import "photoswipe";
import "photoswipe/src/js/ui/photoswipe-ui-default";
import "rallax.js";
import "slick-carousel";

import ScrollReveal from "scrollreveal";

import "../css/main.scss";

const slideUp = {
  duration: 2000,
  distance: "40px",
  origin: "bottom",
  opacity: 0,
};

const slideUp2 = {
  delay: 100,
  duration: 2000,
  distance: "140px",
  origin: "bottom",
  opacity: 0,
};

const slideUp3 = {
  delay: 300,
  duration: 2000,
  distance: "140px",
  origin: "bottom",
  opacity: 0,
};

const slideUp4 = {
  delay: 500,
  duration: 2000,
  distance: "140px",
  origin: "bottom",
  opacity: 0,
};

const slideIn = {
  duration: 2000,
  distance: "200px",
  origin: "right",
  opacity: 0,
};

const slideIn2 = {
  duration: 2000,
  distance: "200px",
  origin: "left",
  opacity: 0,
};

// Scroll reveal
const reveal = function() {
  ScrollReveal().reveal(".title-content", slideIn);
  ScrollReveal().reveal(".pricing-content > div:nth-child(1)", slideUp2);
  ScrollReveal().reveal(".pricing-content > div:nth-child(2)", slideUp3);
  ScrollReveal().reveal(".pricing-content > div:nth-child(3)", slideUp4);
  ScrollReveal().reveal(".services-content > div:nth-child(1) .service", slideUp2);
  ScrollReveal().reveal(".services-content > div:nth-child(2) .service", slideUp3);
  ScrollReveal().reveal(".services-content > div:nth-child(3) .service", slideUp4);
  ScrollReveal().reveal(".text-with-image > div, .text-with-image > a, .text-with-image > figure", slideIn2);
  ScrollReveal().reveal(".text-with-image-unscale > div, .text-with-image-unscale > a, .text-with-image-unscale > figure", slideIn2);
  ScrollReveal().reveal(".text-with-image-unscale-reverse > div, .text-with-image-unscale-reverse > a, .text-with-image-unscale-reverse > figure", slideIn2);
  ScrollReveal().reveal(".text-with-image-reverse > figure, .text-with-image-reverse > div", slideIn);
  ScrollReveal().reveal(".text-with-image-vertical > figure, .text-with-image-vertical > a, .text-with-image-vertical-reverse > figure", slideIn);
  ScrollReveal().reveal(".text-with-image-vertical > div, .text-with-image-vertical-reverse > div", slideIn2);
  ScrollReveal().reveal(".reveal", slideUp);
  ScrollReveal().reveal(".page-title", slideUp);
  ScrollReveal().reveal(".post-item", slideUp);
  ScrollReveal().reveal(".post-detail", slideUp);
  ScrollReveal().reveal(".title-navigation li", slideUp);
};

window.reveal = reveal;
reveal();

$(window).on("load", function() {
  setTimeout(() => {
    $("body").addClass("loaded");
  }, 2000);
});

$(document).ready(() => {

  const $items = {
    win: $(window),
    doc: $(document),
    body: $("body"),
    content: $(".content"),
    top: $(".top"),
    menuButton: $(".menu-button"),
    mainMenu: $(".main-menu"),
    titlePageContent: $(".title-page-content"),
    slideDownTitle: $(".slide-down-title"),
    contactFormMessage: $(".contact-form form .form-message"),
    sidemenuMenuItem: $(".side-menu .side-menu-container a"),
    sidemenuText: $(".side-menu .side-menu-text"),
    sidemenuTextItem: $(".side-menu .side-menu-text > div"),
  }

  $('.carousel-slick:not(.short)').each(function() {
    $(this).slick({
      arrows: true,
      autoplay: true,
      autoplaySpeed: 6000,
      slidesToScroll: 2,
      slidesToShow: 4,
      responsive: [{
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
        }
      }, {
        breakpoint: 575,
        settings: {
          slidesToShow: 2,
        }
      }]
    });
  });

  $('.carousel-slick.short').each(function() {
    $(this).slick({
      arrows: true,
      autoplay: true,
      autoplaySpeed: 6000,
      slidesToScroll: 1,
      slidesToShow: 3,
      responsive: [{
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        }
      }, {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
        }
      }]
    });
  });

  // Menu color
  const colorMenu = function() {
    var a = $(window).scrollTop();

    if (a > 1) {
      $items.top.addClass("colored");
    } else {
      $items.top.removeClass("colored");
    }
  }
  colorMenu();
  $(window).scroll(function () {
    colorMenu();
  });


  // Menu toggle
  $items.mainMenu.removeClass("hidden");

  $items.menuButton.on("click", function() {
    if ($(this).hasClass("active")) {
      $(this).removeClass("active");
      $items.top.removeClass("active");
      $items.mainMenu.removeClass("active");
      //$(".title-page, .container, .contacts, .partners, .light, .container-first").removeClass("blur").addClass("unblur");
    } else {
      $(this).addClass("active");
      $items.top.addClass("active");
      $items.mainMenu.addClass("active");
      //$(".title-page, .contacts, .partners, .light, .container-first").addClass("blur").removeClass("unblur");
    }
  });

  // Anchor click
  $items.content.on("click", "a[href^='#']", function(event) {
    event.preventDefault();
    $("html, body").animate({
      scrollTop: ($($.attr(this, "href")).offset().top - $items.top.outerHeight() * 2)
    }, 800, 'swing');
  });

  if (window.location.hash) {
    var hash = window.location.hash;
    $('html, body').animate({
      scrollTop: ($(hash).offset().top - $items.top.outerHeight() * 2)
    }, 800, 'swing');
  }

  // Rallax
  if ($(".rallax").length > 0) {
    $(".rallax").each(function() {
      const parallax = rallax($(this)[0], { speed: $(this).attr("data-speed") });
    });
  }

  // Menu item click
  $items.mainMenu.on("click", "a[href^='#']", function() {
    $items.menuButton.removeClass("active");
    $items.mainMenu.removeClass("active");
    $items.top.removeClass("active");
    $("html, body").animate({
      scrollTop: ($($(this).attr("href")).offset().top - $items.top.outerHeight() * 2)
    }, 1600);
  });

  // Slide slide down
  $items.slideDownTitle.on("click", function() {
    if ($(this).hasClass("active")) {
      $(this).parent().find(".slide-down-content").stop().slideUp();
      $(this).removeClass("active");
    } else {
      $(this).parent().find(".slide-down-content").stop().slideDown();
      $(this).addClass("active");
    }
  });

  // Alert scrollTop
  if ($items.contactFormMessage.length != 0) {
    $("html, body").animate({
      scrollTop: (($items.contactFormMessage).offset().top - ($items.top.height() + 50))
    }, 800);
  }

  // Side menu handle
  function setActiveSidemenu() {
    $items.sidemenuMenuItem.removeClass("active");
    $items.sidemenuMenuItem.first().addClass("active");
    $items.sidemenuTextItem.each(function(i) {
      if ($items.win.scrollTop() > $(this).offset().top - ($items.top.outerHeight() * 2) - 10) {
        $items.sidemenuMenuItem.removeClass("active");
        $items.sidemenuMenuItem.eq(i).addClass("active");
      }
    });
  };

  if ($items.sidemenuText) {
    setActiveSidemenu();
    $items.win.on("scroll", setActiveSidemenu);
  }
  $items.sidemenuMenuItem.on("click", function(e) {
    e.preventDefault();
    $("html, body").animate({
      scrollTop: ($($(this).attr("href").split('/').pop()).offset().top - $items.top.outerHeight() * 2)
    }, 800, 'swing');
  });

});

import "./gallery";
